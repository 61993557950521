















import { Vue, Component } from 'vue-property-decorator'

@Component({
  components: {
    IconLogoGray: () => import('@/components/UI/icons/IconLogoGray.vue'),
  },
})
export default class MainError extends Vue {
  get errorText (): string {
    return this.$t('team.errorMessages.403', {
      email: window.FEATURES.support_email,
    }).toString()
  }
}
